/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';  // Assuming Axios instance is imported
import withAuthCheck from '../hoc/withAuthCheck';
import { getCurrentTimeGMT } from '../utils/utils';
import Sidebar from '../utils/Sidebar.js';
import handleErrors from '../auth/handleErrors';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';

const ListOpinionCampaigns = () => {
  const [groupCampaigns, setGroupCampaigns] = useState([]);
  const [singleCampaigns, setSingleCampaigns] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [, setIsLoggedIn] = useState(true);
  const navigate = useNavigate();

  // Logout handler
  const handleLogout = async () => {
    try {
      await api.post('/logout');  // Call backend logout endpoint
      localStorage.removeItem('access_token');  // Clear the access token from localStorage
      setIsLoggedIn(false);  // Update login state
      navigate('/login');  // Redirect to login after logout
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  // Fetch opinion campaigns
  useEffect(() => {
    const fetchOpinionCampaigns = async () => {
      try {
        setErrorMessage('');
        const response = await api.get('/list_opinion_campaigns');  // Fetch the opinion campaigns
        setGroupCampaigns(response.data.group_campaigns || []); // Ensure it's an array
        setSingleCampaigns(response.data.single_campaigns || []); // Ensure it's an array
        setErrorMessage('');  // Clear any previous error message
      } catch (error) {
        handleErrors(error, navigate, fetchOpinionCampaigns, setErrorMessage);
      }
    };

    fetchOpinionCampaigns();
  }, [navigate]);

  const handleViewOpinionCampaign = (opinionSessionId, pollId) => {
    console.log(`Viewing opinion session with ID: ${opinionSessionId}`);
    navigate(`/viewOpinionCampaign/${opinionSessionId}/${pollId}`);  // Redirect to the opinion session page
  };

  const handleCancel = () => {
    navigate('/dashboard');
  };

  const handleViewSingleOpinionCampaign = (pollId, opinionSessionId) => {
    // Navigate to the route with pollId and opinionSessionId
    navigate(`/viewSingleOpinionResults/${pollId}/${opinionSessionId}`);
  };

  const getSessionStatus = (opinionSession) => {
    const sessionStartTime = new Date(opinionSession.session_timestamp);
    const currentTime = new Date();
    const hoursDifference = Math.abs(currentTime - sessionStartTime) / 36e5;

    return opinionSession.status === 1 ? (hoursDifference > 4 ? 'Expired' : 'Active') : 'Closed';
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main Content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Opinion Campaigns</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        <h2>Your Campaigns</h2>

        {/* Display error message if exists */}
        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}


        <dl style={{ color: "black", listStyleType: 'none', padding: 0 }}>
          <div>
            <h2>Opinion Campaigns</h2>

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

            {/* Render Group Campaigns */}
            <h3>Group Campaigns</h3>
            {groupCampaigns.length > 0 ? (
              groupCampaigns.map((campaign) => (
                <div key={campaign.opinion_session_id} style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px' }}>
                  <p><strong>Group Name:</strong> {campaign.group_name}</p>
                  <p><strong>Campaign ID:</strong> {campaign.opinion_session_id}</p>
                  <p><strong>Current Time:</strong> {getCurrentTimeGMT()}</p>
                  <p><strong>Campaign Start Date:</strong> {campaign.session_timestamp}</p>
                  <p><strong>Campaign Strength:</strong> {campaign.total_count}</p>
                  <p><strong>Poll ID:</strong> {campaign.poll_uuid}</p>
                  <p><strong>Poll Title:</strong> {campaign.poll_title}</p>
                  <p><strong>Poll Duration:</strong> {campaign.poll_duration} hours</p>
                  <p><strong>Status:</strong> {campaign.status}</p>
                  <p><strong>Poll Options:</strong></p>
                  <ul>
                    {campaign.options.map((option, index) => (
                      <li key={index}>{option}</li>
                    ))}
                  </ul>
                  <p><strong>Poll Count:</strong></p>
                  {Object.values(campaign.poll_count).every(count => count === 0) ? (
                    <p>None</p>
                  ) : (
                    <ul>
                      {Object.entries(campaign.poll_count).map(([option, count], index) => (
                        <li key={index}>{option}: {count} votes</li>
                      ))}
                    </ul>
                  )}
                  <p>
                    <button onClick={() => handleViewOpinionCampaign(campaign.opinion_session_id, campaign.poll_uuid,)}>
                      View Group Opinion Campaign
                    </button>
                  </p>
                </div>
              ))
            ) : (
              <p>No group campaigns found.</p>
            )}

            {/* Render Single Campaigns */}
            <h3>Single Campaigns</h3>
            {singleCampaigns.length > 0 ? (
              singleCampaigns.map((campaign) => (
                <div key={campaign.opinion_session_id} style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px' }}>
                  <p><strong>Campaign ID:</strong> {campaign.opinion_session_id}</p>
                  <p><strong>Current Time:</strong> {getCurrentTimeGMT()}</p>
                  <p><strong>Campaign Start Date:</strong> {campaign.session_timestamp}</p>
                  <p><strong>Campaign Strength:</strong> {campaign.total_count}</p>
                  <p><strong>Poll ID:</strong> {campaign.poll_uuid}</p>
                  <p><strong>Poll Title:</strong> {campaign.poll_title}</p>
                  <p><strong>Poll Duration:</strong> {campaign.poll_duration} hours</p>
                  <p><strong>Status:</strong> {campaign.status}</p>
                  <p><strong>Poll Options:</strong></p>
                  <ul>
                    {campaign.options.map((option, index) => (
                      <li key={index}>{option}</li>
                    ))}
                  </ul>
                  <p><strong>Poll Count:</strong></p>
                  <ul>
                    {Object.entries(campaign.poll_count).map(([option, count], index) => (
                      <li key={index}>{option}: {count} votes</li>
                    ))}
                  </ul>
                  <p>
                    <button onClick={() => handleViewSingleOpinionCampaign(campaign.poll_uuid, campaign.opinion_session_id)}>
                      View Single Opinion Campaign
                    </button>
                  </p>
                </div>
              ))
            ) : (
              <p>No single campaigns found.</p>
            )}
            {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}
          </div>
        </dl>

        <button onClick={handleCancel} style={{ marginTop: '20px' }}>Cancel</button>
      </div>
    </div>
  );
};

export default withAuthCheck(ListOpinionCampaigns);
