import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoIcon from '../aipolls_logo_1563x1563.png';

const Sidebar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [expandedCategory, setExpandedCategory] = useState(null);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);
  const toggleCategory = (category) => {
    if (!isSidebarOpen) {
      setSidebarOpen(true); // Expand the sidebar if it's collapsed
    }
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  const categories = [
    {
      name: 'Trivia Polls',
      icon: '/Trivia.png',
      links: [
        { label: 'Create Trivia', url: '/createTriviaPoll' },
        { label: 'List Trivia', url: '/triviaPolls' },
        { label: 'Manage Trivia', url: '/listTriviaCampaigns' },
        { label: 'Create Trivia Groups', url: '/createTriviaGroup' },
        { label: 'List Trivia Groups', url: '/listTriviaGroups' },
        { label: 'Manage Trivia Groups', url: '/ManageTriviaGroupPolls' },
      ],
    },
    {
      name: 'Opinion Polls',
      icon: '/Opinions.png',
      links: [
        { label: 'Create Opinions', url: '/createOpinionPoll' },
        { label: 'List Opinions', url: '/opinionPolls' },
        { label: 'Manage Opinions', url: '/opinionCampaigns' },
        { label: 'Create Opinion Groups', url: '/createOpinionGroup' },
        { label: 'List Opinion Groups', url: '/listOpinionGroups' },
        { label: 'Manage Opinion Groups', url: '/ManageOpinionGroupPolls' },
      ],
    },
    {
      name: 'Research Polls',
      icon: '/ResearchPoll.png',
      links: [
        { label: 'Create Research', url: '/createResearchPoll' },
        { label: 'List Research', url: '/researchPolls' },
        { label: 'Manage Research', url: '/researchCampaigns' },
        { label: 'Create Research Groups', url: '/createResearchGroup' },
        { label: 'List Research Groups', url: '/listResearchGroups' },
        { label: 'Manage Research Groups', url: '/ManageResearchGroupPolls' },
      ],
    },
  ];

  return (
    <div className="sidebar">
                <div className="logo">
                    <Link to="/tryWizard">
                        <img alt="AIPolls.Net" title="AIPolls.Net" src={logoIcon} style={{ width: '75px', height: '75px' }} />
                    </Link>
                </div>
    <div style={{ width: isSidebarOpen ? '250px' : '60px', transition: 'width 0.3s ease', backgroundColor: '#ecf0f1', padding: '10px', borderRight: '2px solid #ddd' }}>
      {/* Sidebar Toggle Button */}
      <div
        onClick={toggleSidebar}
        style={{
          cursor: 'pointer',
          marginBottom: '20px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '10px',
        }}
      >
        <img
          src={isSidebarOpen ? '/CollapseSidebar.png' : '/ExpandSidebar.png'}
          alt="Toggle Sidebar"
          style={{
            width: '30px',
            height: '30px',
            borderRadius: '5px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            transition: 'transform 0.1s ease, box-shadow 0.1s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.boxShadow = '0px 6px 12px rgba(0, 0, 0, 0.3)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)';
          }}
        />
      </div>

      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
        {categories.map((category, index) => (
          <li key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', borderRadius: '5px', overflow: 'hidden', backgroundColor: '#ecf0f1' }}>
            {/* Category Header with Icon and Label */}
            <div
              onClick={() => toggleCategory(category.name)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                backgroundColor: '#eaeaea',
                borderBottom: expandedCategory === category.name ? '1px solid #ddd' : 'none',
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#d9d9d9')}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#eaeaea')}
              title={isSidebarOpen ? '' : category.name} // Show tooltip if sidebar is closed
            >
              <img src={category.icon} alt={category.name} style={{ width: '30px', height: '30px', marginRight: isSidebarOpen ? '10px' : '0' }} />
              {isSidebarOpen && <span style={{ fontWeight: 'bold', color: '#333' }}>{category.name}</span>}
            </div>

            {/* Dropdown Links */}
            {expandedCategory === category.name && (
              <ul style={{ listStyleType: 'none', padding: '5px 15px', margin: 0, backgroundColor: '#ecf0f1' }}>
                {category.links.map((link, linkIndex) => (
                  <li key={linkIndex} style={{ marginBottom: '5px' }}>
                    <a
                      href={link.url}
                      style={{ textDecoration: 'none', color: '#555', padding: '8px', display: 'block', borderRadius: '3px' }}
                      onMouseEnter={(e) => (e.target.style.backgroundColor = '#e0e0e0')}
                      onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}
                    >
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
    </div>
  );
};

export default Sidebar;