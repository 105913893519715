/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { refreshToken } from '../auth/auth.js'; // Assuming refreshToken is implemented to handle token refresh
import withAuthCheck from '../hoc/withAuthCheck';
import Sidebar from '../utils/Sidebar.js';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';

const VerifyEmail = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Logout handler needs to be defined at the top level
  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Call your backend logout endpoint
      localStorage.removeItem('access_token'); // Clear the access token from localStorage
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleProfile = () => {
    // Navigate back to the profile
    navigate('/profile');
  };

  // Function to create a new poll
  const handleVerifyEmail = async (e) => {
    if (e) e.preventDefault();

    try {
      const response = await api.post('/verify_email', { email });
      //setPollId(response.data.poll_id);
      setErrorMessage('/login');
      navigate('/'); // Navigate to list after poll creation
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        try {
          const newAccessToken = await refreshToken();
          if (newAccessToken) {
            handleVerifyEmail(); // Retry creating poll after refreshing token
          } else {
            setErrorMessage('Failed to refresh token. Please log in again.');
            navigate('/login');
          }
        } catch (refreshError) {
          setErrorMessage('Session expired. Please log in again.');
          navigate('/login');
        }
      } else {
        setErrorMessage(error.response.data.message || 'Failed to create poll.');
      }
    } else {
      setErrorMessage('No response received from server.');
    }
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net - Because Everythings A Poll</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        <h2>Create a Poll</h2>
        <form onSubmit={handleVerifyEmail}>
          <div>
            <label>
              Question:
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: '75%' }}
                required
              />
            </label>
          </div>

          <button type="submit">Verify Email</button>
        </form>

        {email && <p>Email sent to {email}</p>}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default withAuthCheck(VerifyEmail);
