import React, { useState, useCallback, useEffect } from 'react';
import api from '../api'; // Axios instance
import '../App.css'; // Import the PollTaker-specific CSS
import { useNavigate, Link } from 'react-router-dom';
import Sidebar from '../utils/Sidebar.js';
import { triviaExamples, opinionExamples, researchExamples } from '../utils/examples';
import { isWhitelisted } from '../utils/whitelist';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import { getFutureDateString } from '../utils/utils';
import logoIcon from '../aipolls_logo_1563x1563.png';
import { isLoggedIn } from '../auth/auth.js';

const TryPoll = () => {
    const seedRandom = (seed) => {
        let x = Math.sin(seed++) * 7500;
        return x - Math.floor(x);
    };

    const getSeededRandomExample = useCallback((examplesArray, seed) => {
        const randomIndex = Math.floor(seedRandom(seed) * examplesArray.length);
        return examplesArray[randomIndex];
    }, []);

    // Initialize with a dynamic seed value
    const seed = Date.now(); // You can also use any dynamic number for more variation
    const triviaExample = getSeededRandomExample(triviaExamples, seed);
    const opinionExample = getSeededRandomExample(opinionExamples, seed + 1);
    const researchExample = getSeededRandomExample(researchExamples, seed + 2);

    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    const [expiry, setExpiry] = useState('');
    const [showError, setShowError] = useState(false);
    const [pollType, setPollType] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isTitleEdited, setIsTitleEdited] = useState(false);
    const [isDescriptionEdited, setIsDescriptionEdited] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [pollData, setPollData] = useState({
        question: '',
        title: '',
        description: '',
        options: ['', ''], // Start with two options, can add more later
        correctAnswer: '', // For trivia
        pollType: '', // 'trivia', 'opinion', or 'research'
        expiryHours: '', // For trivia and opinion
        expiryDate: '', // For research
        email: '',
    });

    const allOptionsFilled = pollData.options.every(option => option.trim() !== '');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPollData({ ...pollData, [name]: value });

        // If the title field is being edited, set isTitleEdited to true
        if (name === "title") {
            setIsTitleEdited(true);
        }

        if (name === "description") {
            setIsDescriptionEdited(true);
        }
    };

    const handleAddOption = () => {
        setPollData({ ...pollData, options: [...pollData.options, ''] });
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...pollData.options];
        newOptions[index] = value;
        setPollData({ ...pollData, options: newOptions });
    };

    const handleRemoveOption = (index) => {
        const newOptions = pollData.options.filter((_, i) => i !== index);
        setPollData({ ...pollData, options: newOptions });
    };

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handleNextOptionStep = () => {
        if (pollData.pollType === 'trivia' && (!allOptionsFilled || pollData.correctAnswer.trim() === '')) {
            setShowError(true); // Show error message if validation fails for trivia polls
            console.log('Please fill all options and select a correct answer.');
        } else if (!allOptionsFilled) {
            setShowError(true); // Show error message if not all options are filled for non-trivia polls
            console.log('Please fill all options before proceeding.');
        } else {
            setShowError(false); // Clear error and proceed to the next step
            setStep(step + 1);
        }
    };

    // Does not work as expected
    useEffect(() => {
        const checkLogin = async () => {
            const status = await isLoggedIn();
            setLoggedIn(status);
            setIsLoading(false);
        };

        checkLogin(); // Check login status on mount
    }, []);

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then((response) => response.json())
            .then((data) => {
                console.log('IP Address:', data.ip);
                if (!isWhitelisted(data.ip)) {
                    console.error('You do not have permission to access this page.');
                    navigate('/login');
                }
            })
            .catch((error) => {
                console.error('Error fetching IP address:', error);
            });

        if (pollType === 'research') {
            const defaultExpiry = getFutureDateString(720); // Now + 30 days
            console.log('Default Expiry:', defaultExpiry);
            setExpiry(defaultExpiry);
            setPollData((prevData) => ({
                ...prevData,
                expiryDate: defaultExpiry,
            }));
        }

        setPollData((prevData) => ({
            ...prevData,
            title: prevData.title || prevData.question,
            description: prevData.description || prevData.question,
        }));
    }, [pollType, navigate]);

    const handlePollTypeSelection = (type) => {
        setPollType(type);
        setPollData({ ...pollData, pollType: type }); // Update pollData with the selected poll type
        setStep(2); // Move to Step 2 after selection
    };


    const handleSubmitPoll = async () => {
        if (!pollData.acceptedAgreement) {
            setShowError(true); // Show the error message if agreement not accepted
            console.log("You must accept the agreement to proceed.");
            return; // Prevent form submission
        }

        console.log("showError", showError);

        // If agreement is accepted, hide error message and submit poll
        setShowError(false);
        try {
            const response = await api.post('/try_poll', pollData);
            console.log('Poll Created:', response.data);
            await api.post('/send_poll_email', { email: pollData.email, pollLink: response.data.poll_link });
            alert('Poll sent to your email for your confirmation!');
        } catch (error) {
            console.error('Error creating poll:', error);
        }
    };

    // Render different steps based on the current step
    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div>
                        {!loggedIn && (
                            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                <p>Already have an account? <Link to="/login">Login here</Link></p>
                            </div>
                        )}
                        <h2>Pick what you would like to do:</h2>
                        <div style={{ marginBottom: '15px' }}>
                            <label>
                                <input
                                    type="radio"
                                    name="pollType"
                                    value="trivia"
                                    checked={pollType === 'trivia'}
                                    onChange={() => handlePollTypeSelection('trivia')}
                                />
                                Conduct a Trivia Poll <br />
                                <em>Eg. {triviaExample}</em>
                            </label>
                        </div>

                        <div style={{ marginBottom: '15px' }}>
                            <label>
                                <input
                                    type="radio"
                                    name="pollType"
                                    value="opinion"
                                    checked={pollType === 'opinion'}
                                    onChange={() => handlePollTypeSelection('opinion')}
                                />
                                Conduct an Opinion Poll <br />
                                <em>Eg. {opinionExample}</em>
                            </label>
                        </div>

                        <div style={{ marginBottom: '15px' }}>
                            <label>
                                <input
                                    type="radio"
                                    name="pollType"
                                    value="research"
                                    checked={pollType === 'research'}
                                    onChange={() => handlePollTypeSelection('research')}
                                />
                                Conduct a Market Research Poll <br />
                                <em>Eg. {researchExample}</em>
                            </label>
                        </div>
                    </div>
                );
            case 2:
                const getPlaceholder = () => {
                    switch (pollData.pollType) {
                        case 'trivia':
                            return triviaExample; // Use the trivia example if poll type is trivia
                        case 'opinion':
                            return opinionExample; // Use the opinion example if poll type is opinion
                        case 'research':
                            return researchExample; // Use the research example if poll type is research
                        default:
                            return 'Enter your poll question'; // Default placeholder if no type selected
                    }
                };

                const handleUseExample = () => {
                    const exampleText = getPlaceholder();
                    setPollData({ ...pollData, question: exampleText });
                };

                return (
                    <div>
                        {pollData.pollType === 'trivia' && <h2>You selected Trivia Poll</h2>}
                        {pollData.pollType === 'opinion' && <h2>You selected Opinion Poll</h2>}
                        {pollData.pollType === 'research' && <h2>You selected Research Poll</h2>}
                        Try a poll now! Do you have a poll question?
                        <h2>Enter Question</h2>
                        <input
                            type="text"
                            name="question"
                            value={pollData.question}
                            onChange={handleInputChange}
                            placeholder={getPlaceholder()}
                            required
                            style={{ width: '75%' }}
                        />
                        <p />
                        <p />
                        <button onClick={handleUseExample}>Suggest New Poll</button>
                        <p />
                        <button onClick={handleNextStep}>Use This Poll</button>
                    </div>
                );
            case 3:
                return (
                    <div>
                        {pollData.pollType === 'trivia' && <h2>You selected Trivia Poll</h2>}
                        {pollData.pollType === 'opinion' && <h2>You selected Opinion Poll</h2>}
                        {pollData.pollType === 'research' && <h2>You selected Research Poll</h2>}
                        <h3>We copied over your question, but feel free to give an intuitive title to your poll.</h3>
                        <div className="poll-summary-box">
                            <p>Question: {pollData.question}</p>
                        </div>
                        <h2>Enter Title</h2>
                        <input
                            type="text"
                            name="title"
                            value={pollData.title}
                            onChange={handleInputChange}
                            style={{ width: '75%' }}
                        />
                        <p />
                        <button onClick={handleNextStep}>Next</button>
                    </div>
                );
            case 4:
                return (
                    <div>
                        {pollData.pollType === 'trivia' && <h2>You selected Trivia Poll</h2>}
                        {pollData.pollType === 'opinion' && <h2>You selected Opinion Poll</h2>}
                        {pollData.pollType === 'research' && <h2>You selected Research Poll</h2>}
                        <h3>Now add some more description, so you can help your audience understand what your question is about.</h3>
                        <div className="poll-summary-box">
                            <p>Question: {pollData.question}</p>
                            <p>Title: {pollData.title || pollData.question}</p>
                        </div>
                        <h2>Enter Description</h2>
                        <input
                            type="text"
                            name="description"
                            value={pollData.description}
                            onChange={handleInputChange}
                            style={{ width: '75%' }}
                        />
                        <p />
                        <button onClick={handleNextStep}>Next</button>
                    </div>
                );
            case 5: // Assuming step 5 is for entering options and expiry details
                return (
                    <div>
                        {pollData.pollType === 'trivia' && <h2>You selected Trivia Poll</h2>}
                        {pollData.pollType === 'opinion' && <h2>You selected Opinion Poll</h2>}
                        {pollData.pollType === 'research' && <h2>You selected Research Poll</h2>}
                        <h3>Now it's time to enter some options or answer choices.</h3>
                        <div className="poll-summary-box">
                            <p>Question: {pollData.question}</p>
                            <p>Title: {pollData.title || pollData.question}</p>
                            <p>Description: {pollData.description || pollData.question}</p>
                        </div>
                        <h2>Enter Options</h2>
                        {pollData.options.map((option, index) => (
                            <div key={index}>
                                <input
                                    type="text"
                                    value={option}
                                    onChange={(e) => handleOptionChange(index, e.target.value)}
                                    placeholder={`Option ${index + 1}`}
                                    required
                                    style={{ width: '75%' }}
                                />
                                <button type="button" onClick={() => handleRemoveOption(index)}>Remove</button>
                            </div>
                        ))}
                        <p />
                        <button onClick={handleAddOption}>Add Option</button>
                        <p />

                        {/* If poll type is trivia, allow the user to select a correct answer */}
                        {pollData.pollType === 'trivia' && (
                            <div>
                                <h3>Select the correct answer:</h3>
                                <select
                                    value={pollData.correctAnswer}
                                    onChange={(e) => setPollData({ ...pollData, correctAnswer: e.target.value })}
                                    required
                                    style={{ width: '75%' }}
                                >
                                    <option value="">Select correct answer</option>
                                    {pollData.options.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {/* If poll type is trivia or opinion, allow expiryHours, otherwise expiryDate for research */}
                        {pollData.pollType === 'trivia' || pollData.pollType === 'opinion' ? (
                            <div>
                                <label>
                                    Expiry (in hours):
                                    <input
                                        type="number"
                                        value={pollData.expiryHours}
                                        onChange={(e) => setPollData({ ...pollData, expiryHours: parseInt(e.target.value, 10) || 0 })}
                                        required
                                    />
                                </label>
                            </div>
                        ) : pollData.pollType === 'research' ? (
                            <div>
                                <label>
                                    Expiry Date:
                                    <input
                                        type="datetime-local"
                                        value={expiry}
                                        onChange={(e) => {
                                            setExpiry(e.target.value);
                                            setPollData({ ...pollData, expiryDate: e.target.value });
                                        }}
                                        required
                                    />
                                </label>
                            </div>
                        ) : null}
                        <p />
                        <button onClick={handleNextOptionStep}>
                            Next
                        </button>
                        {showError && (
                            <div style={{ color: 'red', marginTop: '10px' }}>
                                {pollData.pollType === 'trivia'
                                    ? 'Please fill all options and select a correct answer for the trivia.'
                                    : 'Please fill all options before proceeding.'}
                            </div>
                        )}
                    </div>
                );
            case 6:
                return (
                    <div>
                        {pollData.pollType === 'trivia' && <h2>You selected Trivia Poll</h2>}
                        {pollData.pollType === 'opinion' && <h2>You selected Opinion Poll</h2>}
                        {pollData.pollType === 'research' && <h2>You selected Research Poll</h2>}
                        <div className="poll-summary-box">
                            <p>Question: {pollData.question}</p>
                            <p>Title: {pollData.title || pollData.question}</p>
                            <p>Description: {pollData.description || pollData.question}</p>
                            <p>Options:</p>
                            {pollData.options.map((option, index) => (
                                <div key={index}>
                                    <input type="text" value={option} readOnly style={{ width: '75%' }} />
                                </div>
                            ))}
                        </div>
                        <h2>Ready to launch your campaign? Enter email to receive a link.</h2>
                        <input
                            type="email"
                            name="email"
                            value={pollData.email}
                            onChange={handleInputChange}
                            placeholder="abc@domain.com"
                            required
                            style={{ width: '75%' }}
                        />
                        <p />
                        {/* Checkbox to accept the agreement */}
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                checked={pollData.acceptedAgreement || false} // Ensure it's false by default if undefined
                                onChange={(e) => {
                                    setPollData({ ...pollData, acceptedAgreement: e.target.checked });
                                    console.log("onChange triggered, pollData.acceptedAgreement", pollData.acceptedAgreement);
                                    setShowError(false); // Clear error if they check the box
                                }}
                                id="accept-agreement"
                                style={{ marginRight: '10px' }}
                            />
                            <label htmlFor="accept-agreement">
                                I accept the <a href={`/api/disclosure_agreement`} target="_blank" rel="noopener noreferrer">disclosure agreement</a>
                            </label>
                        </div>

                        {/* Show error message if checkbox is not checked */}
                        {showError && <p style={{ color: 'red' }}>You must accept the agreement to proceed.</p>}

                        {/* Disable the "Submit Poll" button until the agreement is accepted */}
                        <button onClick={handleSubmitPoll}>
                            Submit Poll
                        </button>


                    </div>
                );
            default:
                return <div>Invalid Step</div>;
        }
    };

    if (isLoading) {
        return <div>Loading...</div>; // Avoid rendering other UI before login status is determined
    }

    return <div>{
        <div className="container">
            {/* Sidebar Menu */}
            <Sidebar />
            {/* Main content */}
            <div className="main-content">
                <div className="header">
                    <div className="logo">AIPolls.Net - Because Everythings A Poll</div>
                    <div className="auth-buttons">
                        <button>
                            <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                        <button>
                            <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                    </div>
                </div>
                {
                    renderStep()
                }
            </div>
        </div>
    }
    </div>;
};

export default TryPoll;
