/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import withAuthCheck from '../hoc/withAuthCheck';
import handleErrors from '../auth/handleErrors';
import Sidebar from '../utils/Sidebar.js';
import '../App.css';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';

const Dashboard = () => {
  const [polls, setPolls] = useState([]);
  const [opinionPolls, setOpinionPolls] = useState([]);
  const [researchPolls, setResearchPolls] = useState([]);
  const [triviaCampaigns, setTriviaCampaigns] = useState([]);
  const [opinionCampaigns, setOpinionCampaigns] = useState([]);
  const [researchCampaigns, setResearchCampaigns] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  // Logout handler needs to be defined at the top level
  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Call your backend logout endpoint
      localStorage.removeItem('access_token'); // Clear the access token from localStorage
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch polls
        navigate('/tryWizard');
        /*
        const response = await api.get('/trivia_polls');
        setPolls(response.data);

        const opinionResponse = await api.get('/opinion_polls');
        setOpinionPolls(opinionResponse.data);

        const researchResponse = await api.get('/research_polls');
        setResearchPolls(researchResponse.data);

        const triviaCampaignsResponse = await api.get('/list_trivia_campaigns');
        setTriviaCampaigns(triviaCampaignsResponse.data);

        const opinionCampaignsResponse = await api.get('/opinion_campaigns');
        setOpinionCampaigns(opinionCampaignsResponse.data);

        const researchCampaignsResponse = await api.get('/research_campaigns');
        setResearchCampaigns(researchCampaignsResponse.data);
        */
      } catch (error) {
        handleErrors(error, navigate, fetchData, setErrorMessage);
      }
    };

    fetchData();
  }, [navigate]);

  const handleProfile = () => {
    // Navigate back to the profile
    navigate('/profile');
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Analytics Dashboard</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        {/* Error message display */}
        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}

        {/* Poll Statistics */}
        <div className="poll-statistics">
          <p><b>Trivia Polls</b>: {polls.length}</p>
          <p><b>Opinion Polls</b>: {opinionPolls.length}</p>
          <p><b>Research Polls</b>: {researchPolls.length}</p>
          <p><b>Trivia Campaigns</b>: {triviaCampaigns.length}</p>
          <p><b>Opinion Campaigns</b>: {opinionCampaigns.length}</p>
          <p><b>Research Campaigns</b>: {researchCampaigns.length}</p>
        </div>
      </div>
    </div>
  );
};

export default withAuthCheck(Dashboard);
